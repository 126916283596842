import styled from "styled-components";

const UnstyledAnchor = styled.a`
  &,
  &:hover,
  &:visited {
    text-decoration: none;
    color: inherit;
  }
`;

export default UnstyledAnchor;
