import React from "react";
import { graphql } from "gatsby";

import Layout, { ConstrainedWidth } from "../components/Layout";
import { IndexPageTemplateQuery } from "../../graphql-types";
import NotFoundPage from "../pages/404";
import UnstyledList from "../components/simple/UnstyledList";
import UnstyledAnchor from "../components/simple/UnstyledAnchor";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";

type Props = NonNullable<
  NonNullable<IndexPageTemplateQuery["markdownRemark"]>["frontmatter"]
>;
type LinkProps = NonNullable<Props["links"]>[0];

const LinkImage = styled(GatsbyImage)`
  border-radius: 50%;
`;

const LinkText = styled.div``;

const LinkLabel = styled.div`
  font-size: 30px;
`;
const LinkDescription = styled.div`
  font-weight: 200;
`;

const UnstyledLink: React.FC<LinkProps & { className?: string }> = ({
  className,
  image,
  label,
  description,
  url
}) => (
  <li className={className}>
    <UnstyledAnchor href={url || undefined}>
      {image?.childImageSharp?.fluid && (
        <LinkImage fluid={image.childImageSharp.fluid} alt={`${label} image`} />
      )}
      <LinkText>
        <LinkLabel>{label || ""}</LinkLabel>
        <LinkDescription>{description || ""}</LinkDescription>
      </LinkText>
    </UnstyledAnchor>
  </li>
);

const Link = styled(UnstyledLink)`
  a {
    display: flex;
    flex-direction: row;
    align-items: center;

    ${LinkImage} {
      flex: 0 0 150px;

      @media (max-width: 450px) {
        flex-basis: 100px;
      }

      margin-right: 20px;
    }

    ${LinkText} {
      flex: 1 1 auto;
      min-width: 200px;
    }
  }
  margin-bottom: 15px;
`;

export const IndexPageTemplate: React.FC<Props> = ({ header, links }) => (
  <ConstrainedWidth>
    <h1>{header}</h1>
    <nav>
      <UnstyledList>
        {links?.map((link, i) => (
          <Link key={link?.url || i} {...link} />
        ))}
      </UnstyledList>
    </nav>
  </ConstrainedWidth>
);

const IndexPage: React.FC<{ data: IndexPageTemplateQuery }> = ({ data }) => {
  const frontmatter = data.markdownRemark?.frontmatter;

  if (!frontmatter) return <NotFoundPage />;
  return (
    <Layout title={frontmatter.title}>
      <IndexPageTemplate {...frontmatter} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        header
        links {
          label
          description
          url
          image {
            childImageSharp {
              fluid(maxWidth: 150, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
